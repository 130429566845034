import { PencilIcon } from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import { Vehicle } from "../graphql/generated/graphql";
import { classNames } from "../utils/formatters";

export default function EditVehicleButton({
  loading,
  asIcon,
  data,
}: {
  loading?: boolean;
  asIcon?: boolean;
  data: Vehicle;
}) {
  const router = useRouter();

  function goToEdit(e) {
    e.preventDefault();
    e.stopPropagation();
    const pathname = `/editar/${data._id}`;
    router.push(
      {
        pathname,
      },
      pathname
    );
  }

  if (asIcon) {
    return (
      <PencilIcon
        className={classNames(
          "  h-6 w-6 p-0.5 z-10 text-gray-500 rounded-full ",
          " hover:text-orange-500",
          loading ? "opacity-70" : ""
        )}
        aria-hidden="true"
        onClick={goToEdit}
      />
    );
  }

  return (
    <button
      disabled={loading}
      className="-ml-px relative inline-flex items-center space-x-2 rounded-md border-2 border-green-600 bg-green-600 px-3 sm:px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 hover:border-green-500 disabled:opacity-70"
      onClick={goToEdit}
    >
      <PencilIcon className="h-5 w-5" aria-hidden="true" />
      <span className="hidden sm:inline">Editar</span>
    </button>
  );
}
