import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React from "react";
import { useGetUserWatchlistQuery } from "../graphql/generated/graphql";
import useWatchlistMutations from "../hooks/useWatchlistMutations";
import { classNames } from "../utils/formatters";
import { isInWatchlist } from "../utils/watchlist";
import Spinner from "./Spinner";

export default function GridItemWatchlistButton({ item }) {
  const {
    addVehicleToWatchlist,
    removeVehicleFromWatchlist,
    addingToWatchlist,
    removingFromWatchlist,
  } = useWatchlistMutations(item);

  const router = useRouter();

  const { status: sessionStatus } = useSession();

  const getUserWatchlistResponse = useGetUserWatchlistQuery({
    skip: sessionStatus !== "authenticated",
  });

  const { loading: loadingWatchlist } = getUserWatchlistResponse;

  const inWatchlist = isInWatchlist(getUserWatchlistResponse, item._id);

  const loading =
    sessionStatus === "loading" ||
    addingToWatchlist ||
    removingFromWatchlist ||
    loadingWatchlist;

  function add(e) {
    e.preventDefault();
    e.stopPropagation();
    if (sessionStatus === "unauthenticated") {
      router.push("/auth/signin");
    } else {
      addVehicleToWatchlist();
    }
  }

  function remove(e) {
    e.stopPropagation();
    if (sessionStatus === "unauthenticated") {
      router.push("/auth/signin");
    } else {
      removeVehicleFromWatchlist();
    }
  }

  if (loading) {
    return (
      <Spinner className=" h-6 w-6 p-1 z-10 text-orange-600 rounded-full " />
    );
  }

  if (inWatchlist) {
    return (
      <HeartIconSolid
        className={classNames(
          "  h-6 w-6 p-0.5 z-10 text-red-600 rounded-full ",
          " hover:text-red-500",
          loading ? "opacity-70" : ""
        )}
        aria-hidden="true"
        onClick={loading ? null : remove}
      />
    );
  }

  return (
    <HeartIcon
      className={classNames(
        "  h-6 w-6 p-0.5 z-10 rounded-full text-gray-500 bg-opacity-70 ",
        " hover:bg-opacity-100 hover:text-red-500",
        loading ? "opacity-70" : ""
      )}
      aria-hidden="true"
      onClick={loading ? null : add}
    />
  );
}
