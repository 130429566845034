import { TrashIcon } from "@heroicons/react/24/solid";
import { Vehicle } from "../graphql/generated/graphql";
import { classNames } from "../utils/formatters";

export default function DeleteVehicleButton({
  data,
  onClick,
  loading,
  asIcon,
}: {
  data: Vehicle;
  onClick: (d: Vehicle) => void;
  loading?: boolean;
  asIcon?: boolean;
}) {
  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onClick(data);
  }

  if (asIcon) {
    return (
      <TrashIcon
        className={classNames(
          "  h-6 w-6 p-0.5 z-10 text-gray-500 rounded-full ",
          " hover:text-orange-500",
          loading ? "opacity-70" : ""
        )}
        aria-hidden="true"
        onClick={loading ? null : handleClick}
      />
    );
  }

  return (
    <button
      onClick={handleClick}
      disabled={loading}
      className="-ml-px relative inline-flex items-center space-x-2 rounded-md border-2 border-red-600 bg-red-600 px-3 sm:px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 hover:border-red-500 disabled:opacity-70"
    >
      <TrashIcon className="h-5 w-5" aria-hidden="true" />
      <span className="hidden sm:inline">Eliminar</span>
    </button>
  );
}
