import Image from "next/image";
import {
  BUMP,
  RENEW_MAX_DISCOUNTED,
  RENEW_MAX_STANDARD,
  RENEW_PLUS_DISCOUNTED,
  RENEW_PLUS_STANDARD,
  TIER_KEYS,
  VALID_PURCHASES,
} from "../utils/constants";
import { classNames } from "../utils/formatters";
import { FaAngleDoubleUp } from "react-icons/fa";

export const TIER_COLORS = {
  [TIER_KEYS.MAX]: "bg-blue-500",
  [RENEW_MAX_DISCOUNTED.id]: "bg-blue-500",
  [RENEW_MAX_STANDARD.id]: "bg-blue-500",
  // [TIER_KEYS.PREMIUM]: "bg-emerald-500",
  [TIER_KEYS.PLUS]: "bg-emerald-500",
  [RENEW_PLUS_DISCOUNTED.id]: "bg-emerald-500",
  [RENEW_PLUS_STANDARD.id]: "bg-emerald-500",
  [BUMP.id]: "bg-orange-500",
  [TIER_KEYS.COLLECTION]: "bg-black", //TODO
};
export const TIER_COLORS_HOVER = {
  [TIER_KEYS.MAX]: "hover:bg-blue-600",
  [RENEW_MAX_DISCOUNTED.id]: "hover:bg-blue-600",
  [RENEW_MAX_STANDARD.id]: "hover:bg-blue-600",
  // [TIER_KEYS.PREMIUM]: "bg-emerald-600",
  [TIER_KEYS.PLUS]: "hover:bg-emerald-600",
  [RENEW_PLUS_DISCOUNTED.id]: "hover:bg-emerald-600",
  [RENEW_PLUS_STANDARD.id]: "hover:bg-emerald-600",
  [BUMP.id]: "hover:bg-orange-600",
};

export default function TierBadge({
  tier,
  quantity,
  large,
  className,
}: {
  tier: string;
  quantity?: number;
  large?: boolean;
  className?: string;
}) {
  if (tier && tier !== TIER_KEYS.FREE) {
    return (
      <span
        className={classNames(
          "inline-flex items-center rounded-full font-medium text-white shadow-md ",
          large ? "text-lg pl-3 pr-2 py-1" : "text-xs pl-2.5 pr-1 py-0.5",
          TIER_COLORS[tier],
          className
        )}
      >
        {tier === TIER_KEYS.COLLECTION ? (
          <Image
            src="/collection.png"
            alt="Logo Movi Collection"
            width={75}
            height={13.65}
            className="pr-1.5"
          />
        ) : (
          <span className="inline-flex items-center ">
            {quantity ? `${quantity}x ` : ""}
            {VALID_PURCHASES[tier]?.name}
            {/* <span className="h-5 w-5">{View}</span> */}
            <FaAngleDoubleUp className="w-5 animate-bounce" />
          </span>
        )}
      </span>
    );
  }

  return null;
}
